<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'user-manage',
  data() {
    return {
      deptTree: [],
      searchParam: {
        plantCd: null,
        keyword: '',
        useFlag: 'Y',
      },
      deptData: {
        plantCd: null,
        keyword: '',
        useFlag: 'Y',
      },
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchUrl: '',
      tab: 'authGroup',
      tabItems: [
        { name: 'authGroup', icon: 'groups', label: '권한 관리', component: () => import(`${'./authGroup.vue'}`) },
        { name: 'authGroupMenu', icon: 'list', label: '권한별 메뉴', component: () => import(`${'./authGroupMenu.vue'}`) },
        { name: 'authGroupUser', icon: 'manage_accounts', label: '권한별 사용자', component: () => import(`${'./authGroupUser.vue'}`) },
        { name: 'authGroupByUser', icon: 'badge', label: '사용자별 권한', component: () => import(`${'./authGroupByUser.vue'}`) },
        { name: 'authPlant', icon: 'home_work', label: '사용자별 공사현장권한', component: () => import(`${'./authGroupPlant.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getDeptList();
    },
    selected() {
      alert(1);
    },
    ticked() {
      alert(2);
    },
    getDeptList() {
      this.deptTree = [
        {
          deptCd: 'd1',
          deptNm: '최상단메뉴',
          icon: 'corporate_fare',
          children: [
            {
              deptCd: 'd21',
              deptNm: '메뉴1',
              icon: 'corporate_fare',
              children: [
                { deptCd: 'd31', deptNm: '하위메뉴1', icon: 'corporate_fare', },
                { deptCd: 'd32', deptNm: '하위메뉴2', icon: 'corporate_fare', }
              ]
            },
            {
              deptCd: 'd22',
              deptNm: '메뉴2',
              icon: 'corporate_fare',
              children: [
                { deptCd: 'd33', deptNm: '하위메뉴3', icon: 'corporate_fare', },
                { deptCd: 'd34', deptNm: '하위메뉴4', icon: 'corporate_fare', }
              ]
            },
            {
              deptCd: 'd23',
              deptNm: '메뉴3',
              icon: 'corporate_fare',
              children: [
                { deptCd: 'd35', deptNm: '하위메뉴5', icon: 'corporate_fare', },
                { deptCd: 'd36', deptNm: '하위메뉴6', icon: 'corporate_fare', },
                { deptCd: 'd37', deptNm: '하위메뉴7', icon: 'corporate_fare', }
              ]
            },
            {
              deptCd: 'd234',
              deptNm: '메뉴34',
              icon: 'corporate_fare',
              children: [
                { deptCd: 'd354', deptNm: '하위메뉴5', icon: 'corporate_fare', },
                { deptCd: 'd364', deptNm: '하위메뉴6', icon: 'corporate_fare', },
                { deptCd: 'd374', deptNm: '하위메뉴7', icon: 'corporate_fare', }
              ]
            },
            {
              deptCd: 'd235',
              deptNm: '메뉴3',
              icon: 'corporate_fare',
              children: [
                { deptCd: 'd356', deptNm: '하위메뉴5', icon: 'corporate_fare', },
                { deptCd: 'd366', deptNm: '하위메뉴6', icon: 'corporate_fare', },
                { deptCd: 'd376', deptNm: '하위메뉴7', icon: 'corporate_fare', }
              ]
            }
          ]
        }
      ];
    },
    rowClick(row) {
      this.riskHazard = row
    },
    reset() {
      Object.assign(this.$data.riskHazard, this.$options.data().riskHazard);
    }
  }
};
</script>
